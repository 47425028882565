import Vue from "vue";
import App from "./App.vue";
import vSelect from "vue-select";
import dataV from "@jiaminghi/data-view";
import hevueImgPreview from "hevue-img-preview";
import "vue-select/dist/vue-select.css";
import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import "@/options/font";
import { fontSize } from "@/options/font";
import Message from "vue-m-message";
import "vue-m-message/dist/index.css";
import "./core/lazy_use";
import Vuex from "vuex";
import router from "./router";
import store from "./store/";
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import themePluginConfig from '../config/themePluginConfig'
import i18n from './locales'
import {storePlugin} from './utils/storeStorage';
import * as turf from "@turf/turf";

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style

Vue.use(dataV);
Vue.use(hevueImgPreview);
Vue.use(VueGoodTablePlugin);
Vue.component("v-select", vSelect);
Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(storePlugin);


// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)

window.umi_plugin_ant_themeVar = themePluginConfig.theme

Vue.prototype.$turf = turf;
Vue.prototype.$fontSize = fontSize;
Vue.prototype.$message = Message;

new Vue({
  render: (h) => h(App),
  store,
  router,
  i18n,
  created: bootstrap,
  beforeCreate() {
    Vue.prototype.$bus = this; //安装全局事件总线
  },
}).$mount("#app");
