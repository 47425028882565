// base64转换为blod
function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

// blod转化为file  theBlod: blod格式文件  fileName: 文件名称  fileType:文件类型
function blobToFile(theBlob, fileName, fileType) {
  let file = new window.File([theBlob], fileName, { type: fileType });
  return file;
}

function compressPic(file, size, device, resolve) {
  const reader = new FileReader(); // 创建 FileReader
  reader.readAsDataURL(file);
  reader.onload = ({ target: { result: src } }) => {
    const image = new Image(); // 创建 img 元素
    image.src = src;
    const canvas = document.createElement("canvas"); // 创建 canvas 元素
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      canvas.getContext("2d").drawImage(image, 0, 0, image.width, image.height); // 绘制 图片到canvas上
      let canvasURL, nearFile; // 创建变量 图片的file 最接近目标大小的file
      let pointDirection = true; // 设置默认的二分方向 true为加二分值 false为减二分值
      let quality = 0;
      for (let i = 1; i <= device; i++) {
        canvasURL = canvas.toDataURL(
          file.type,
          pointDirection ? (quality += 1 / 2 ** i) : (quality -= 1 / 2 ** i)
        );
        let blob = dataURLtoBlob(canvasURL); // 此方法为base64转化为blod方法 见上方
        nearFile = blobToFile(blob, file.name, file.type); // 此方法为blod转file方法
        // 上方调用的两个方法  dataURLtoBlod和blobToFile已经更新到下方文章中 可拿来直接使用

        pointDirection = !(nearFile.size / 1024 > size);
      }
      nearFile.uid = file.uid;
      resolve(nearFile);
    };
  };
}

export { compressPic };
