<template>
  <div id="container"></div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
// 设置安全密钥
window._AMapSecurityConfig = {
  securityJsCode: "96cb0050e607f2558de4ca505b7e3895",
};
export default {
  name: "MapView",
  data() {
    return {
      map: null,
      satationDrawRange: this.satationDrawRangeVal,
      remoteLayer: null,
      tiandiMapLayer: null,
      tiandiTextLayer: null,
      openstreetmapLayer: null,
      mapboxMapLayer: null,
      vectorLayer2: null,
      vectorSource: null,
    };
  },
  mounted() {
    this.initGaodeMap();
  },
  methods: {
    initGaodeMap() {
      AMapLoader.load({
        key: "e4cb63f6df6fe30dc2d1947366515fbf",
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          // 初始化地图
          this.map = new AMap.Map("container", {
            viewMode: "2D", //  是否为3D地图模式
            zoom: 10, // 初始化地图级别
            center: [120.37, 36.1], //中心点坐标  郑州
            resizeEnable: true,
          });
          var toolbar = new AMap.Scale();
          this.map.addControl(toolbar);
          this.$emit("getGaoDeMap", this.map);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped lang="less">
#container {
  clear: both;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
