import 'vue';
import store from 'store';

import sessionStorage from 'store/storages/sessionStorage';
import localStorage from 'store/storages/localStorage';

export const sessionStor = store.createStore(sessionStorage);
export const localStor = store.createStore(localStorage);


export const storePlugin = (V) => {
    Object.defineProperty(V.prototype, '$session', {value: sessionStor, writable: true});
    Object.defineProperty(V.prototype, '$local', {value: localStor, writable: true});
};

export default sessionStor;
