<template>
  <div id="map"></div>
</template>

<script>
import { colorDict, markScale } from '../options'
import { gridsetName, gridNames, remoteBaseUrl, remoteFormat, wktToGeo, geoToWkt,pointToWkt,strToWkt,strToWkt2 } from '../utils/utils'
import gcoord from 'gcoord'
export default {
  name: 'MapView',
  data() {
    return {
      map: null,
      satationDrawRange: this.satationDrawRangeVal,
      remoteLayer: null,
      tiandiMapLayer: null,
      tiandiTextLayer: null,
      mapboxMapLayer: null,
      vectorLayer2: null,
      draw: null,
      vectorSource: null,
      addSource: new ol.source.Vector(),
      vectorLayer: null,
      thirdLayer:null,
    }
  },
  props: {
    satationDrawRangeVal: {
      type: Number,
      default: 5000, //默认值
    },
    remoteLayerInfo: {
      required: true,
    },
  },
  mounted() {
    this.initMap()
  },
  watch: {
    remoteLayerInfo: {
      deep: true,
      handler() {
        this.changeRemote()
      },
    },
  },
  methods: {
    initMap() {
      const projection = new ol.proj.Projection({
        code: 'EPSG:4326',
        units: 'degrees',
        axisOrientation: 'neu',
      })

      this.remoteLayer = new ol.layer.Tile({
        source: this.constructSource(this.layerName),
        name: '遥感影像图',
        type: 'remote',
        visible: true,
      })

      const view = new ol.View({
        center: [0, 0],
        zoom: 2,
        // resolutions: resolutions,
        projection: projection,
        // extent: [-180.0, -90.0, 180.0, 90.0],
        minZoom: 2,
        maxZoom: 19,
      })

      this.mapboxMapLayer = new ol.layer.Tile({
        source: new ol.source.XYZ({
          url: 'https://api.mapbox.com/styles/v1/wangjun1303/cjsog4hnr2zuw1fpotj4j5i9f/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoid2FuZ2p1bjEzMDMiLCJhIjoiY2l4OHBscnYwMDAwdTJ5cWY5cnRrZGM0ZSJ9.FQy75sTd5x1dujqvTsLzvA',
          wrapX: false,
        }),
        preload: true,
        crossOrigin: 'anonymous',
        projection: 'EPSG:4326',
        id: 'mapbox',
        visible: true,
        isGroup: true,
        name: 'mapboxMap',
      })

      this.tiandiMapLayer = new ol.layer.Tile({
        source: new ol.source.XYZ({
          url: 'https://t0.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=dfb8269733afd739918d830a53036206',
          wrapX: false,
        }),
        crossOrigin: 'anonymous',
        projection: 'EPSG:4326',
        id: 'tianditu',
        visible: false,
        isGroup: true,
        name: '天地图',
      })

      this.tiandiTextLayer = new ol.layer.Tile({
        source: new ol.source.XYZ({
          url: 'https://t0.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=dfb8269733afd739918d830a53036206',
          wrapX: false,
        }),
        crossOrigin: 'anonymous',
        projection: 'EPSG:4326',
        id: 'tianditu_text',
        visible: false,
        isGroup: true,
        name: '天地图文字标注',
      })

      this.map = new ol.Map({
        target: 'map',
        layers: [this.mapboxMapLayer, this.tiandiMapLayer, this.remoteLayer, this.tiandiTextLayer],
        view: view,
      })

      this.moveMap()
      this.map.getView().on('change:resolution', () => {
        this.addFeatures()
      })
      this.$nextTick(function () {
        this.$emit('getMap', this.map)
      })
    },

    addFeatures(areasData) {
      if (areasData) {
        this.areasData = areasData
      }
      this.removeFeatures()
      // 获取缩放级别
      const zoom = this.map.getView().getZoom()

      if (zoom < 14 && zoom > 6) {
        this.markFeature = new ol.layer.Vector({
          source: new ol.source.Vector(),
        })

        // 构建svg的Image对象
        var svg =
          '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">' +
          '<path fill="#156BB1" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>' +
          '<circle fill="#FFFFFF" cx="15" cy="10.677" r="3.291"/></svg>'

        var mysvg = new Image()
        mysvg.src = 'data:image/svg+xml,' + escape(svg)

        const anchorStyle = new ol.style.Style({
          image: new ol.style.Icon({
            img: mysvg, // 设置Image对象
            imgSize: [30, 30], // 及图标大小
          }),
        })

        anchorStyle.getImage().setScale(markScale[zoom] || 0.1)

        if (this.areasData?.length) {
          for (let item of this.areasData) {
            if (item.zstFlag) {
              const anchor = new ol.Feature({
                geometry: new ol.geom.Point([item.lng, item.lat]),
              })
              anchor.setStyle(anchorStyle)
              this.markFeature.getSource().addFeature(anchor)
            }
          }
        }
        this.map.addLayer(this.markFeature)
      }
    },

    removeFeatures() {
      this.markFeature && this.map.removeLayer(this.markFeature)
      this.markFeature = null
    },

    drawPolygons(areasData) {
      this.removePolygons()
      if (areasData?.length) {
        let features = []
        for (let item of areasData) {
          let coordinates = []
          if (item.geomJson) {
            coordinates = JSON.parse(item.geomJson).coordinates
          }

          const polygon = new ol.geom.MultiPolygon(coordinates)

          const feature = new ol.Feature(polygon)
          item.type = 'areas'
          feature.setProperties(item)
          features.push(feature)
        }
        this.vectorSource = new ol.source.Vector()
        this.vectorSource.addFeatures(features)
        this.vectorLayer = new ol.layer.Vector({
          source: this.vectorSource,
          style: function (feature) {
            const name = feature.getProperties().bareareaT
            const color = name ? colorDict[name] : '#FF7F00'

            const style = new ol.style.Style({
              //填充色
              fill: new ol.style.Fill({
                color: 'rgba(255, 255, 255, 0.15)',
              }),
              //边线颜色
              stroke: new ol.style.Stroke({
                color: color,
                width: 2,
              }),
              //形状
              image: new ol.style.Circle({
                radius: 7,
                fill: new ol.style.Fill({
                  color: '#ffcc33',
                }),
              }),
            })
            return [style]
          },
          zIndex: 9,
        })

        this.map.addLayer(this.vectorLayer)
      }
    },
    // 清除多边形区域（裸土区域）
    removePolygons() {
      this.vectorLayer && this.map.removeLayer(this.vectorLayer)
    },
    // 绘制标站
    drawStation(station, el) {
      const pos = ol.proj.fromLonLat([station.lng, station.lat], 'EPSG:4326')
      var marker = new ol.Overlay({
        id: station.sn,
        position: pos,
        // positioning: "center-center",
        element: el,
        stopEvent: false,
      })
      this.map.addOverlay(marker)
    },
    // 删除所有标站
    removeStation(reStations) {
      for (const iterator of reStations) {
        let _overlay = this.map.getOverlayById(iterator.sn)
        this.map.removeOverlay(_overlay)
      }
    },
    // 绘制标站方圆区域
    drawStationCircle(stations, drowRange) {
      let sourcePoint = new ol.source.Vector() // 创建数据源
      let layerPoint = new ol.layer.Vector({
        // 创建图层
        zIndex: 8, // 图层的层级
        projection: 'EPSG:4326', // 图层地理信息
      })
      layerPoint.setSource(sourcePoint) // 把数据源绑定到图层上面
      this.map.addLayer(layerPoint) // 把图层添加到地图上面去
      let features = []
      for (const station of stations) {
        const pos = ol.proj.fromLonLat([station.lng, station.lat], 'EPSG:4326')
        // let feature = new ol.Feature({
        //   geometry: new ol.geom.Circle(pos, this.getRadius(drowRange)),
        // })
        let feature = this.getRadius(pos, drowRange);
        feature.setStyle(
          new ol.style.Style({
            fill: new ol.style.Fill({
              color: 'rgba(32, 157, 230, 0.3)',
            }),
          })
        )
        feature.setProperties({
          // 设置其他的数据
          name: 'stationCircle',
          sn: station.sn,
          type: 'station',
        })
        feature.setId(station.sn)
        features.push(feature)
      }
      sourcePoint.addFeatures(features)
    },
    // 删除标站方圆区域
    deleteStationCircle(stations) {
      for (let index = 0; index < this.map.getLayers().getProperties().length; index++) {
        let layers = this.map.getLayers().getArray()
        for (let i in layers) {
          let source = layers[i].getSource()
          if (source instanceof ol.source.Vector) {
            for (const station of stations) {
              let fea = source.getFeatureById(station.sn)

              if (fea) {
                source.removeFeature(fea)
              }
            }
          }
        }
      }
    },
    getRadius(drowRange) {
      let metersPerUnit = this.map.getView().getProjection().getMetersPerUnit()
      let circleRadius = drowRange / metersPerUnit
      return circleRadius
    },
    drawRegionBoundary(regionBoundaryDatas) {
      if (regionBoundaryDatas?.length) {
        let features = []
        for (let item of regionBoundaryDatas) {
          let feature = this.getFeatureByWKT(item.data)
          feature.setProperties({ type: 'regionBoundary', name: item.name })
          features.push(feature)
        }
        const vectorSource = new ol.source.Vector()
        vectorSource.addFeatures(features)
        if (!this.vectorLayer2) {
          this.vectorLayer2 = new ol.layer.Vector({
            source: vectorSource,
            style: function () {
              const color = '#ffffff'
              const style = new ol.style.Style({
                // //填充色
                // fill: new ol.style.Fill({
                //   color: "rgba(255, 255, 255, 0.15)",
                // }),
                //边线颜色
                stroke: new ol.style.Stroke({
                  color: color,
                  width: 2,
                }),
                //形状
                image: new ol.style.Circle({
                  radius: 7,
                  fill: new ol.style.Fill({
                    color: '#ffcc33',
                  }),
                }),
              })
              return [style]
            },
            zIndex: 8,
          })
          this.map.addLayer(this.vectorLayer2)
        } else {
          this.vectorLayer2.setSource(vectorSource)
        }
      }
    },
    drawBdRegionBoundary(regionBoundaryDatas) {
      const vectorSource = new ol.source.Vector()
      for (let index = 0; index < regionBoundaryDatas.length; index++) {
        const {data} = regionBoundaryDatas[index]
        console.log("🚀 ~ file: MapView.vue:347 ~ drawBdRegionBoundary ~ data:", data)
         if (data.type === 'bdRegion') {
          let features = []
          let bdFeature = this.getFeatureByWKT(data.districts[0].polyline, 'bd09')
          bdFeature.setProperties({ type: 'regionBoundary', name: 'bd' })
          features.push(bdFeature)
          vectorSource.addFeatures(features)
        } else if (data.type === 'gdRegion') {
          let features = []
          let gdFeature = this.getFeatureByWKT(data.districts[0].polyline, 'gcj02')
          gdFeature.setProperties({ type: 'regionBoundary', name: 'gd' })
          features.push(gdFeature)
          vectorSource.addFeatures(features)
        }else {
          let features = []
          for (let item of data) {
            let feature = this.getFeatureByWKT(item.data)
            feature.setProperties({ type: 'regionBoundary', name: item.name })
            features.push(feature)
          }
          vectorSource.addFeatures(features)
        }
      }

      // let features = [];
      // let bdFeature = this.getFeatureByWKT(data);
      // bdFeature.setProperties({ type: "regionBoundary", name: 'bd' });
      // features.push(bdFeature);
      // vectorSource.addFeatures(features);

      if (!this.vectorLayer2) {
        this.vectorLayer2 = new ol.layer.Vector({
          source: vectorSource,
          style: function (feature) {
            const name = feature.getProperties().name
            let color = '#ffffff'
            if (name === 'bd') {
              color = '#ff0000'
            }else if (name ==='gd'){
              color = '#00ffff'
            }
            // color = name === 'bd' ? '#ff0000' : '#ffffff'
            const style = new ol.style.Style({
              //边线颜色
              stroke: new ol.style.Stroke({
                color: color,
                width: 2,
              }),
              //形状
              image: new ol.style.Circle({
                radius: 7,
                fill: new ol.style.Fill({
                  color: '#ffcc33',
                }),
              }),
            })
            return [style]
          },
          zIndex: 8,
        })
        this.map.addLayer(this.vectorLayer2)
      } else {
        this.vectorLayer2.setSource(vectorSource)
      }
    },
    deleteRegionBoundary() {
      if (this.vectorLayer2) {
        this.vectorLayer2.getSource().clear()
        this.map.removeLayer(this.vectorLayer2)
        this.vectorLayer2 = null
      }
    },
    /**
     * @todo wkt格式数据转化成图形对象
     * @param {string} wkt   "POINT(112.7197265625,39.18164062499999)" 格式数据
     * @param {string|Projection} sourceCode 源投影坐标系
     * @param {string|Projection} targetCode 目标投影坐标系
     * @returns {Feature}
     */
    getFeatureByWKT(wkt, coordinate, sourceCode, targetCode) {
      if (!coordinate) {
        try {
          let view = this.map.getView()
          if (!wkt) {
            return null
          }
          // 数据格式类型
          let format = new ol.format.WKT()

          let feature
          feature = format.readFeature(wkt, {
            featureProjection: targetCode || view.getProjection(),
            dataProjection: sourceCode || view.getProjection(),
          })

          return feature
        } catch (e) {
          console.log(e)
          return null
        }
      } else if(coordinate === 'bd09') {
        let view = this.map.getView()
        let geo = wktToGeo(wkt)
        var result = gcoord.transform(
          geo, // 经纬度坐标
          gcoord.BD09, // 当前坐标系
          gcoord.WGS84 // 目标坐标系
        )

        // 数据格式类型
        let format = new ol.format.GeoJSON()
        let feature
          feature = format.readFeature(result, {
            featureProjection: targetCode || view.getProjection(),
            dataProjection: sourceCode || view.getProjection(),
          })
          return feature
      } else if(coordinate === 'gcj02') {
        let view = this.map.getView()
        let wkt2 = strToWkt2(wkt)
        console.log("🚀 ~ file: MapView.vue:475 ~ getFeatureByWKT ~ geo:", wkt2)

        let geo = wktToGeo(wkt2)
        // geo.type = "Polygon"
        // geo.coordinates = [geo.coordinates]
        console.log("🚀 ~ file: MapView.vue:479 ~ getFeatureByWKT ~ geo:", geo)

        var result = gcoord.transform(
          geo, // 经纬度坐标
          gcoord.GCJ02, // 当前坐标系
          gcoord.WGS84 // 目标坐标系
        )
        console.log("🚀 ~ file: MapView.vue:486 ~ getFeatureByWKT ~ result:", result)


        // 数据格式类型

        let format = new ol.format.GeoJSON()
        let feature
          feature = format.readFeature(result, {
            featureProjection: targetCode || view.getProjection(),
            dataProjection: sourceCode || view.getProjection(),
          })
          return feature
      }
    },
    constructSource() {
      const style = ''
      const projection = new ol.proj.Projection({
        code: 'EPSG:4326',
        units: 'degrees',
        axisOrientation: 'neu',
      })
      const resolutions = [
        0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125, 0.02197265625, 0.010986328125, 0.0054931640625,
        0.00274658203125, 0.001373291015625, 6.866455078125e-4, 3.4332275390625e-4, 1.71661376953125e-4,
        8.58306884765625e-5, 4.291534423828125e-5, 2.1457672119140625e-5, 1.0728836059570312e-5, 5.364418029785156e-6,
        2.682209014892578e-6, 1.341104507446289e-6, 6.705522537231445e-7, 3.3527612686157227e-7,
      ]
      const baseParams = ['VERSION', 'LAYER', 'STYLE', 'TILEMATRIX', 'TILEMATRIXSET', 'SERVICE', 'FORMAT']

      const params = {
        VERSION: '1.0.0',
        LAYER: this.remoteLayerInfo.layerName,
        STYLE: style,
        TILEMATRIX: gridNames,
        TILEMATRIXSET: gridsetName,
        SERVICE: 'WMTS',
        FORMAT: remoteFormat,
      }

      var url = remoteBaseUrl + '?'

      for (var param in params) {
        if (baseParams.indexOf(param.toUpperCase()) < 0) {
          url = url + param + '=' + params[param] + '&'
        }
      }
      url = url.slice(0, -1)

      let extend = [
        this.remoteLayerInfo.minX,
        this.remoteLayerInfo.minY,
        this.remoteLayerInfo.maxX,
        this.remoteLayerInfo.maxY,
      ]

      const source = new ol.source.WMTS({
        url: url,
        layer: params['LAYER'],
        matrixSet: params['TILEMATRIXSET'],
        format: params['FORMAT'],
        projection: projection,
        tileGrid: new ol.tilegrid.WMTS({
          tileSize: [256, 256],
          extent: extend,
          origin: [-180.0, 90.0],
          resolutions: resolutions,
          matrixIds: params['TILEMATRIX'],
        }),
        style: params['STYLE'],
        wrapX: true,
      })
      return source
    },
    changeRemote() {
      this.remoteLayer.setSource(this.constructSource())
      this.moveMap()
    },
    clearRemote() {
      this.remoteLayer.setSource(null)
    },
    moveMap() {
      let extend = [
        this.remoteLayerInfo.minX,
        this.remoteLayerInfo.minY,
        this.remoteLayerInfo.maxX,
        this.remoteLayerInfo.maxY,
      ]

      this.map.getView().fit(extend, this.map.getSize())
    },
    mapChange(val) {
      if (val === 'original') {
        this.tiandiMapLayer.setVisible(false)
        this.tiandiTextLayer.setVisible(false)
        this.mapboxMapLayer.setVisible(true)
      } else if (val === 'tiandimap') {
        this.mapboxMapLayer.setVisible(false)
        this.tiandiMapLayer.setVisible(true)
        this.tiandiTextLayer.setVisible(true)
      } else if (val === 'openstreetmap') {
        this.mapboxMapLayer.setVisible(false)
        this.tiandiMapLayer.setVisible(false)
        this.tiandiTextLayer.setVisible(false)
      }
    },
    changeRemoteLaVisible(val) {
      this.remoteLayer.setVisible(val)
    },
    changeBareSoilLaVisible(val) {
      if (val) {
        this.vectorLayer2.style = null
      } else {
        // this.vectorLayer2.style = new ol.style.Style({
        //   // //填充色
        //   // fill: new ol.style.Fill({
        //   //   color: "rgba(255, 255, 255, 0.15)",
        //   // }),
        //   //边线颜色
        //   stroke: new ol.style.Stroke({
        //     color: color,
        //     width: 2,
        //   }),
        //   //形状
        //   image: new ol.style.Circle({
        //     radius: 7,
        //     fill: new ol.style.Fill({
        //       color: "#ffcc33",
        //     }),
        //   }),
        // });
      }
    },
    addInteraction(type) {
      console.log('addInteraction', this.vectorSource)
      // 添加交互绘制控件
      this.draw = new ol.interaction.Draw({
        source: this.vectorSource,
        type: type,
      })
      this.draw.on('drawend', function () {
        console.log('draw', this.vectorSource)
      })
      this.map.addInteraction(this.draw)
    },

    drawPolygon() {
      // 绘制面
      this.addInteraction('Polygon')
    },

    clearDraw() {
      // 清空绘制
      this.addSource.clear()
    },
    exitDraw() {
      // 退出绘制模式
      if (this.draw) {
        this.map.removeInteraction(this.draw)
        // this = null;
      }
    },
    getRadius(pos, drowRange) {
      var options = { units: "meters" };
      var circle = this.$turf.circle(pos, drowRange, options);
      var circleFeature = new ol.format.GeoJSON().readFeature(circle);
      return circleFeature;
    },
    drawPoints(points) {
        var svgBlue =
          '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">' +
          '<path fill="#156BB1" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>' +
          '<circle fill="#FFFFFF" cx="15" cy="10.677" r="3.291"/></svg>'
        var svgRed =
          '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">' +
          '<path fill="#FF0001" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>' +
          '<circle fill="#FFFFFF" cx="15" cy="10.677" r="3.291"/></svg>'
      let features = [];
      let svg = '';
      points.forEach(point => {
        let feature = new ol.Feature({
          geometry: new ol.geom.Point(ol.proj.fromLonLat([point.thirdLng, point.thirdLat], 'EPSG:4326'))
        });

        if(point.correlation){
          svg = 'data:image/svg+xml,' + escape(svgBlue)
        }else{
          svg = 'data:image/svg+xml,' + escape(svgRed)
        }
        feature.setStyle(
          new ol.style.Style({
            image: new ol.style.Icon({
              anchor: [0.5, 1], //锚点
              opacity: 1,
              scale: 1,
              src: svg //图标的URL
            })
          })
        );
        feature.setProperties({valueType:'thirdPoint',thirdId: point.id})
        feature.setProperties(point)
        features.push(feature);
      });

        let source = new ol.source.Vector()
        source.addFeatures(features)
        if (!this.thirdLayer) {
        this.thirdLayer = new ol.layer.Vector({
          zIndex: 10,
          opacity: 1
        })
      }
      this.thirdLayer.setSource(source)
      this.map.addLayer(this.thirdLayer)
      },
      removePoints(){
        this.map.removeLayer(this.thirdLayer)
      },
  },
}
</script>

<style scoped lang="less">
#map {
  clear: both;
  position: relative;
  width: 100%;
  height: 100%;
  ::v-deep .ol-logo-only {
    display: none;
  }
  ::v-deep .ol-zoom {
    top: 1.2em;
    left: 0.8em;
  }
}
#container {
  clear: both;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
