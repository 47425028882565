// eslint-disable-next-line
import { UserLayout, BasicLayout  } from '@/layouts'

const RouteView = {
  name: "RouteView",
  render: (h) => h("router-view"),
};

export const asyncRouterMap = [
  {
    path: "/manage",
    name: "index",
    component: BasicLayout,
    meta: { title: "menu.home", permission: ['manage'] },
    redirect: "/bare-soil",
    children:[
      {
        path: '/bare-soil',
        name: 'bareSoil',
        component: RouteView,
        redirect: '/bare-soil/table-list',
        meta: { title: 'menu.bare-soil',  permission: ['bareSoilManage'] },
        children: [
          {
            path: '/bare-soil/table-list/:pageNo([1-9]\\d*)?',
            name: 'TableList',
            hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
            component: () => import('@/views/list/TableList'),
            meta: { title: 'menu.bare-soil.table-list',icon: 'table', keepAlive: true, permission: ['bareSoilTable'] }
          },
          {
            path: '/bare-soil/edit/',
            name: 'TableListWrapper',
            hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
            component: () => import('@/views/bareSoilEdit'),
            meta: { title: 'menu.bare-soil.edit', icon: 'edit', keepAlive: true, permission: ['bareSoilEdit'] }
          },
        ]
      }
      // {
      //   path: '/list',
      //   name: 'list',
      //   component: RouteView,
      //   redirect: '/list/table-list',
      //   meta: { title: 'menu.list', icon: 'table', permission: ['table'] },
      //   children: [
      //     {
      //       path: '/list/table-list/:pageNo([1-9]\\d*)?',
      //       name: 'TableListWrapper',
      //       hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
      //       component: () => import('@/views/list/TableList'),
      //       meta: { title: 'menu.list.table-list', keepAlive: true, permission: ['table'] }
      //     },
      //   ]
      // }
    ]
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: "/user",
    component: UserLayout,
    redirect: "/user/login",
    hidden: true,
    children: [
      {
        path: "login",
        name: "login",
        component: () => import(/* webpackChunkName: "user" */ "@/components/user/Login"),
      },
    ],
  },
  {
    path: "/404",
    component: () => import(/* webpackChunkName: "fail" */ "@/views/exception/404"),
  },
  {
    path: "/version/dev/index.html",
    component: () => import(/* webpackChunkName: "HomePage" */ "@/components/HomePage"),
  },
  {
    path: "/",
    component: () => import(/* webpackChunkName: "HomePage" */ "@/components/HomePage"),
  },
];
