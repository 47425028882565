<template>
  <div id="pie" class="pie-main"></div>
</template>

<script>
import { pieOption } from "@/options";
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { PieChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
} from "echarts/components";
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DatasetComponent,
  TransformComponent,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

export default {
  name: "PieChart",
  data() {
    return {};
  },
  mounted() {
    const pieDom = document.getElementById("pie");
    this.pieChart = echarts.init(pieDom);
    this.pieChart.on("click", (params) => {
      this.$emit("onClick", params.data.name);
    });
  },
  methods: {
    updateChart(data) {
      const option = { ...pieOption };
      option.series[0].data = data;
      option.series[0].label.fontSize = this.$fontSize(0.12);
      option.series[0].label.width = this.$fontSize(1);
      option.title.textStyle.fontSize = this.$fontSize(0.16);
      option.title.subtextStyle.fontSize = this.$fontSize(0.16);
      option.title.subtext = data.map((item) => item.value).reduce((a, b) => a + b, 0);
      this.pieChart.setOption(option);
      this.option = option;
    },
  },
};
</script>

<style scoped>
.pie-main {
  width: 100%;
  height: 100%;
}
</style>
