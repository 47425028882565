<template>
  <div>
    <a-spin :spinning="spinning">
      <a-form id="patrolInspectionForm" :form="form" v-bind="formItemLayout" @submit="handleSubmit">
        <a-form-item label="巡查时间" has-feedback>
          <a-date-picker
            style="width: 100%"
            :locale="locale"
            v-decorator="[
              'date',
              {
                rules: [{ required: true, message: '请输入巡查时间!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="巡查图片" extra="请上传.jpg格式的图片">
          <a-upload
            accept=".jpg"
            :file-list="fileList"
            name="file"
            :multiple="true"
            :before-upload="beforeFlowPicFileUpload"
            :transform-file="transformFile"
            list-type="picture"
            :remove="handleRemove"
            v-decorator="[
              'picFile',
              {
                rules: [
                  { required: true, validator: this.uploadFileChange, message: '请添加巡查照片!' },
                ],
              },
            ]"
          >
            <a-button ref="uploadBtn" :disabled="imgTrsing">
              <a-icon type="upload" /> {{ imgTrsStr }}
            </a-button>
          </a-upload>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
          <a-button type="primary" html-type="submit" :disabled="imgTrsing">
            上传巡查记录
          </a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import dayjs from "dayjs";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { uploadInspectionRecords } from "@/api";
import { compressPic } from "../utils/ImageCompression.js";
export default {
  data: () => ({
    formItemLayout: {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    },
    patrolInspectionShow: false,
    locale,
    fileList: [],
    imgTrsing: false,
    imgTrsStr: "点击上传",
    spinning: false,
  }),
  props: {
    gid: {
      type: Number,
      required: true,
    },
    visible: {
      type: Boolean,
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "validate_other" });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let formData = new FormData();
          for (let index = 0; index < this.fileList.length; index++) {
            const element = this.fileList[index];
            formData.append("files", element);
          }
          formData.append("date", dayjs(values.date).format("YYYY-MM-DD"));
          formData.append("gid", this.gid);

          return uploadInspectionRecords(formData).then((res) => {
            const { code } = res.data;
            if (code === 200) {
              this.$message.success("新增巡查记录成功！");
              this.sendReload();
              this.$emit("closePatrolInspection");
            } else {
              this.$message.error("新增巡查记录失败！");
            }
            this.uploadLoading = true;
            this.patrolInspectionShow = false;
          });
        }
      });
    },
    beforeFlowPicFileUpload(file, upfileList) {
      if (upfileList.length <= 0) {
        return false;
      }
      let isAllowed = true;
      if (file && file.size === 0) {
        isAllowed = false;
        this.$message.error("文件大小不能为0!");
        return isAllowed;
      }
      const type = ["jpg"];
      if (!type.includes(file.name.split(".")[file.name.split(".").length - 1].toLowerCase())) {
        isAllowed = false;
        this.$message.error("格式不正确,只允许上传JPG格式文件");
        return isAllowed;
      }

      this.spinning = true;
      this.imgTrsing = true;
      this.imgTrsStr = "图片压缩处理中...";

      return new Promise((resolve, reject) => {
        this.transformFile(file)
          .then((res) => {
            res.status = "uploading";
            res.response = "";
            res.url = "";
            this.fileList.push(res);
            if (this.fileList.length === upfileList.length) {
              this.imgTrsing = false;
              this.imgTrsStr = "点击上传";
              this.spinning = false;
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    transformFile(file) {
      return new Promise((resolve) => {
        if ((file.size / 1024).toFixed(2) < 1024) {
          resolve(file);
        } else {
          compressPic(file, 800, 4, resolve);
        }
      });
    },
    uploadFileChange(rule, value, callback) {
      if (this.fileList.length === 0) {
        return callback("请选择需要上传的文件");
      } else {
        return true;
      }
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    sendReload() {
      this.$bus.$emit("reload");
    },
  },
};
</script>
<style>
#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}
</style>
