<template>
  <div id="station">
    <div class="amap-marker" style="position: absolute; display: block">
      <div
        style="
          z-index: 1;
          border-radius: 5px;
          border: 2px solid black;
          color: white;
          bottom: 5px;
          width: 143.5px;
          transform: translate(-71.75px, -35px) scale(1) rotate(0deg);
        "
      >
        <div
          style="
            background-color: #67ce0b;
            text-align: center;
            padding: 0px 2px;
            margin: 0px;
            height: 20px;
            border-radius: 2px;
            color: #000000;
          "
          :style="{ backgroundColor: bgColor }"
        >
          ★
          <span style="display: inline-block; color: #000000">
            {{ stationData.station + " " + (stationData.pm10 ? stationData.pm10 : "--") }}</span
          >
        </div>
        <div
          class="arrow"
          style="
            background: url('label.png') 0px 0px no-repeat;
            position: absolute;
            width: 6px;
            height: 15px;
            top: 22px;
            left: 66.75px;
            overflow: hidden;
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StationPage",
  data() {
    return {
      stationData: { station: "", pm10: "" },
    };
  },
  computed: {
    bgColor() {
      // 根据数值判断数据颜色
      // if (this.stationData.pm10 === null) {
      //   return "#C0C0C0";
      // } else if (this.stationData.pm10 < 1000) {
      //   return "#67ce0b";
      // } else if (this.stationData.pm10 >= 25) {
      //   return "#FF0000";
      // }

      if (this.stationData.pm10 === null) {
        return "#C0C0C0";
      } else {
        return "#67ce0b";
      }
    },
  },
};
</script>
