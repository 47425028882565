import storage from "store";
import expirePlugin from "store/plugins/expire";
import { login, getInfo, logout } from "@/api/login";
import { ACCESS_TOKEN } from "../mutation-types";
import { welcome,handlePermission } from "../../utils/util";

storage.addPlugin(expirePlugin);
const user = {
  state: {
    token: "",
    name: "",
    welcome: "",
    avatar: "",
    roles: [],
    info: {},
    district: "",
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name;
      state.welcome = welcome;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_DISTRICT: (state, district) => {
      state.district = district;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            const result = response.data.data;
            window.sessionStorage.setItem('ACCESS_TOKEN', result.token);
            // this.$session.set('ACCESS_TOKEN', result.token);
            // storage.set(ACCESS_TOKEN, result.token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
            // commit("SET_TOKEN", result.token);
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        // 请求后端获取用户信息 /api/user/info
        getInfo()
          .then((response) => {
            const result = response.data;
            if (result.role && result.role.permissions.length > 0) {
              const role = { ...result.role };
              // role.permissions = result.role.permissions.map((permission) => {
              //   const per = {
              //     ...permission,
              //     actionList: (permission.actionEntitySet || {}).map((item) => item.action),
              //   };
              //   return per;
              // });
              role.permissionList = [];
              handlePermission(role.permissions,role.permissionList)
              // role.permissionList = role.permissions.map((permission) => {
              //   return permission.permissionId;
              // });
              // 覆盖响应体的 role, 供下游使用
              result.role = role;

              commit("SET_ROLES", role);
              commit("SET_INFO", result);
              commit("SET_NAME", { name: result.nickName, welcome: welcome() });
              commit("SET_DISTRICT", result.district);
              commit("SET_AVATAR", result.avatar);
              // 下游
              resolve(result);
            } else {
              const role = {};
              role.permissionList = [];
              role.permissions = []
              handlePermission(role.permissions,role.permissionList)
              // 覆盖响应体的 role, 供下游使用
              result.role = role;
              commit("SET_ROLES", role);
              commit("SET_INFO", result);
              commit("SET_NAME", { name: result.nickName, welcome: welcome() });
              commit("SET_DISTRICT", result.district);
              commit("SET_AVATAR", result.avatar);
              // 下游
              resolve(result);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            // commit("SET_TOKEN", "");
            commit("SET_ROLES", []);
            sessionStorage.removeItem("ACCESS_TOKEN")
            // storage.remove(ACCESS_TOKEN);
            resolve();
          })
          .catch((err) => {
            console.log("logout fail:", err);
            sessionStorage.removeItem("ACCESS_TOKEN")
            reject()
            // resolve()
          })
          .finally(() => {});
      });
    },
  },

};

export default user;
