var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('a-form',_vm._b({attrs:{"id":"patrolInspectionForm","form":_vm.form},on:{"submit":_vm.handleSubmit}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',{attrs:{"label":"巡查时间","has-feedback":""}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'date',
            {
              rules: [{ required: true, message: '请输入巡查时间!' }],
            },
          ]),expression:"[\n            'date',\n            {\n              rules: [{ required: true, message: '请输入巡查时间!' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"locale":_vm.locale}})],1),_c('a-form-item',{attrs:{"label":"巡查图片","extra":"请上传.jpg格式的图片"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'picFile',
            {
              rules: [
                { required: true, validator: this.uploadFileChange, message: '请添加巡查照片!' },
              ],
            },
          ]),expression:"[\n            'picFile',\n            {\n              rules: [\n                { required: true, validator: this.uploadFileChange, message: '请添加巡查照片!' },\n              ],\n            },\n          ]"}],attrs:{"accept":".jpg","file-list":_vm.fileList,"name":"file","multiple":true,"before-upload":_vm.beforeFlowPicFileUpload,"transform-file":_vm.transformFile,"list-type":"picture","remove":_vm.handleRemove}},[_c('a-button',{ref:"uploadBtn",attrs:{"disabled":_vm.imgTrsing}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" "+_vm._s(_vm.imgTrsStr)+" ")],1)],1)],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 6 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","disabled":_vm.imgTrsing}},[_vm._v(" 上传巡查记录 ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }