import request from '@/utils/request'
import axios from "axios";

// const baseurl = "http://192.168.2.25:7080";
const baseurl = "https://qd.rmtsns.novaecs.com/api";
// const baseurl = "https://qd.sensing.hw.novaecs.com/api";

const userApi = {
  Login: '/sys/auth/login',
  Logout: '/sys/auth/logout',
  UserInfo: '/sys/user/info',
  UpdatePassword: '/sys/user/updatePassword'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
// export function login (parameter) {
//   return request({
//     url: userApi.Login,
//     method: 'post',
//     data: parameter
//   })
// }

export function login(parameter) {
  return axios({
    method: "post",
    url: `${baseurl}${userApi.Login}`,
    data: parameter
  });
}

export function getInfo () {
  return request({
    url: `${baseurl}${userApi.UserInfo}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function logout () {
  return request({
    url: `${baseurl}${userApi.Logout}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function updatePassword (params) {
  return request({
    url: userApi.UpdatePassword,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: params
  })
}

// export function logout() {
//   return axios({
//     method: "post",
//     url: `${baseurl}${userApi.Logout}`,
//   });
// }

