<template>
  <div id="components-layout-demo-basic">
    <a-spin :spinning="spinning">
      <a-layout>
        <a-layout>
          <a-layout-content id="content">
            <div
              style="margin-bottom: 60px"
              v-for="patrolDetail of patrolDetailList"
              :key="patrolDetail.id"
              :id="patrolDetail.patrolTime"
              class="code-box"
            >
              <a-divider style="background-color: #e8e8e8 !important"
                ><h3 style="font-weight: bold">
                  {{ "--" + patrolDetail.patrolTime + "巡查记录" + "--" }}
                </h3></a-divider
              >
              <a-carousel dots-class="slick-dots slick-thumb">
                <a slot="customPaging" slot-scope="props">
                  <img :src="patrolDetail.patrolImgList[props.i].fileDownload" />
                </a>
                <div v-for="imgDetail of patrolDetail.patrolImgList" :key="imgDetail.id">
                  <img
                    @click="previewImg(patrolDetail.id, imgDetail.id)"
                    :src="imgDetail.fileDownload"
                    style="cursor: pointer"
                  />
                </div>
              </a-carousel>
            </div>
          </a-layout-content>
          <a-layout-sider>
            <a-anchor :getContainer="getContainer" class="toc-affix" :offsetTop="130">
              <h3 style="font-weight: bold; margin-left: 18px">巡查时间轴</h3>
              <a-anchor-link
                v-for="patrolDetail of patrolDetailList"
                :key="patrolDetail.id"
                :href="'#' + patrolDetail.patrolTime"
                :title="patrolDetail.patrolTime"
              />
            </a-anchor>
          </a-layout-sider>
        </a-layout>
      </a-layout>
    </a-spin>
  </div>
</template>
<script>
import { getPatrolDetail } from "@/api";
export default {
  name: "PatrolImages",
  components: {},
  data() {
    return {
      picShow: false,
      patrolDetailList: [],
      spinning: false,
    };
  },
  props: {
    gid: {
      type: Number,
      required: true,
    },
    visible: {
      type: Boolean,
    },
  },
  mounted() {
    this.init();
    document.getElementsByClassName("ant-anchor-wrapper");
    this.$bus.$on("reload", () => {
      this.init();
      console.log("我是School组件，收到了数据");
    });
  },
  beforeDestroy() {
    this.$bus.$off("reload");
  },
  methods: {
    init() {
      this.spinning = true;
      return getPatrolDetail(this.gid)
        .then((res) => {
          const { code, data } = res.data;
          if (code == 200) {
            this.patrolDetailList = data;
            this.spinning = false;
            console.log("🚀 ~ file: PatrolImages.vue:56 ~ returngetPatrolDetail ~ data:", data);
          } else {
            this.$message.error("获取数据失败");
          }
        })
        .catch((err) => {
          this.$message.error("获取数据失败");
          console.log("🚀 ~ file: ~ init ~ err:", err);
        });
    },
    onChange(a, b, c) {
      console.log(a, b, c);
    },
    previewImg(id, imgId) {
      let targetList = this.patrolDetailList.filter((x) => x.id === id)[0].patrolImgList;
      let imgs = targetList.map((item) => item.fileDownload);
      let index = targetList.findIndex((x) => x.id == imgId);

      this.$hevueImgPreview({
        nowImgIndex: index,
        imgList: imgs,
        multiple: true,
        clickMaskCLose: true,
      });
    },
    getContainer() {
      return document.getElementsByClassName("ant-modal-body")[0];
    },
  },
};
</script>
<style scoped>
.ant-carousel >>> .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 50%;
}
.ant-carousel >>> .slick-thumb {
  bottom: -5px;
}
.ant-carousel >>> .slick-thumb li {
  width: 60px;
  height: 45px;
}
.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
#components-layout-demo-basic {
  text-align: center;
}
#components-layout-demo-basic .ant-layout-sider {
  background: white;
  color: #fff;
  line-height: 120px;
}

.toc-affix {
  width: 150px;
  position: absolute;
  top: 100px;
  left: 10px;
}
.toc-affix-div {
  width: 150px;
  position: absolute;
  top: 50px;
  left: 10px;
}
.code-box:target {
  border: 1px solid #1890ff;
}
</style>
