<template>
  <router-view />
  <!-- <HomePage></HomePage> -->
</template>

<script>
import HomePage from "@/components/HomePage.vue";
export default {
  components: { HomePage },
  name: "App",
};
</script>

<style>
html,
body,
#app {
  margin: 0;
  padding: 0;
  /* width: 100vw; */
  /* height: 100vh; */
  background-color: #0d132d !important;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
