import { mapState } from "vuex";

const deviceMixin = {
  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile,
    }),
  },
};

export { deviceMixin };
